@use "sass:color"; @use "sass:math"; @use "@/web/client/assets/css/variables" as *; 
.code-block{
    $btn-size: 24px;
    $btn-total-size: $btn-size + $padding;
    $pre-padding: $padding * 2;
    $btn-offset: math.div(($pre-padding + $btn-size + $pre-padding) - ($btn-total-size), 2);

    border: 1px solid $dark;
    overflow: hidden;
    position: relative;

    button{
        border: 1px solid $dark;
        border-radius: math.div($padding, 4);
        background: #eee;
        display: flex;
        align-items: center;
        justify-content: center;
        width: $btn-total-size; height: $btn-total-size;

        position: absolute;
        top: $btn-offset; right: $btn-offset;

        cursor: pointer;
        opacity: 0;
        transition: $transition;

        &:hover{
            background: #ccc;
        }

        svg{
            width: $btn-size;
            height: $btn-size;
        }
    }

    &:hover{
        button{
            opacity: 1;
        }
    }

    :deep(pre.shiki){
        background: $light-bg !important;
        line-height: $btn-size;
        overflow: auto;
        padding: $padding * 2;
        width: 100%;

        code{
            background: unset;
            border-radius: unset;
            padding: 0;
        }
    }
}
