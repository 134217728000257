@use "sass:color"; @use "sass:math"; @use "@/web/client/assets/css/variables" as *; 
.svg-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;

    svg{
        width: $icon-size;
        height: $icon-size;
    }

    &.email{
        svg{
            fill: $dark;
        }
    }

    &.github,
    &.twitter{
        path{
            fill: $dark;
        }
    }
}

.dark{
    .svg-wrapper{
        &.email{
            svg{
                fill: white;
            }
        }

        &.github,
        &.twitter{
            path{
                fill: white;
            }
        }
    }
}
