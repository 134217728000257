@use "sass:color"; @use "sass:math"; @use "@/web/client/assets/css/variables" as *; 
.hero-unit{
    background-color: color.adjust($dark, $lightness: 10%);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    .title{
        &:not(.has-image){
            padding: ($padding * 2) 0;
        }

        &.has-image{
            padding: $vspace 0;

            h1{
                background: rgba($dark, 0.6);
            }
        }

        h1{
            color: white;
            text-shadow: 0 math.div($padding, 8) math.div($padding, 4) rgba($dark, 0.8);

            margin: 0 (-$padding * 2);
            padding: $padding * 2;

            @media (max-width: $mobile-breakpoint) {
                font-size: 2rem;
            }
        }
    }

    .meta{
        background: rgba($dark, 0.8);
        padding: $padding 0;

        time{
            color: white;
            font-size: 1rem;
        }
    }
}

.post-container{
    $gap: $padding * 2;

    --full: minmax(#{$gap}, 1fr);
    --popout: minmax(0, #{$vspace - $gap});
    --content: min(calc(#{$container-width} - #{$gap * 2}), calc(#{$max-container-width} - #{$gap * 2}));

    display: grid;
    gap: $gap;
    grid-template-columns:
        [full-start]
            var(--full)
                [popout-start]
                    var(--popout)
                        [content-start]
                            var(--content)
                        [content-end]
                    var(--popout)
                [popout-end]
            var(--full)
        [full-end];

    @media (max-width: $mobile-breakpoint) {
        display: flex;
        flex-direction: column;
        padding-left: $padding * 2;
        padding-right: $padding * 2;
    }

    > :deep(*){
        grid-column: content;
    }

    > :deep(.grid),
    > :deep(.popout){
        grid-column: popout;
    }

    > :deep(.full){
        grid-column: full;
    }
}
