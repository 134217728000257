@use "sass:color"; @use "sass:math"; @use "@/web/client/assets/css/variables" as *; 
.linked-heading{
    :deep(a) {
        text-decoration: none;
        color: $dark;
        position: relative;

        .svg{
            display: flex;
            align-items: center;
            opacity: 0;
            transition: $transition;

            width: 24px;
            height: 24px;
            position: absolute;
            bottom: calc(-12px + 0.5lh); // Relative to bottom, shift down half of svg 12px (to center icon) then up half lineheight (to center last line)
            right: -24px - math.div($padding, 2);
        }

        &:hover{
            .svg{
                opacity: 1;
            }
        }
    }
}
