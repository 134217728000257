@use "sass:color"; @use "sass:math"; @use "@/web/client/assets/css/variables" as *; 
.math-block{
    border: 1px solid $dark;
    overflow-x: auto;
    padding: ($padding * 2) 0;

    :deep(.katex-display){
        margin: 0;
    }
}
