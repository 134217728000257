@use "sass:color"; @use "sass:math"; @use "@/web/client/assets/css/variables" as *; 
figure{
    &.border{
        picture{
            border: 1px solid $dark;
        }

        figcaption{
            border: 1px solid $dark;
            border-top: none;
        }
    }

    picture{
        background: $light-bg;
        display: block;
        width: 100%;

        img{
            display: block;
            object-fit: cover;
            object-position: center;
            margin: 0 auto;
            max-width: 100%;
        }
    }

    figcaption{
        font-size: 1rem;
        line-height: 1.5;
        padding: $padding;
        text-align: center;
    }
}
