*{
    background: none;
    border: 0;
    box-sizing: border-box;
    margin: 0;
    outline: none;
    padding: 0;

	font-size: 100%;
	vertical-align: baseline;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}
